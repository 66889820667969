import { Box, Button, Paper, Typography, useTheme } from '@material-ui/core';
import React from 'react';


interface Props {
  name: string;
  image: string;
  available: boolean;
  onClick: () => void;
}

export const AdditionalProductGroup: React.FC<Props> = (props: Props) => {

  const theme = useTheme();

  if (props.available) {
    return (
      <Paper style={{padding: 15, marginBottom: 5}} component={Paper} >
        {props.image != null && <img style={{float:"left", margin: 10}} height={50} src={props.image} alt="pes-logo" />}
        <Typography variant="h4" style={{flexGrow: 1}} >{props.name}</Typography>
        <Button  onClick={props.onClick}>Vybrat balíček</Button>
      </Paper>);
  } else {
    return (
      <Box style={{padding: 15, marginBottom: 5, background: theme.palette.secondary.light }} component={Paper} >
        {props.image != null && <img style={{float:"left", margin: 10, filter: "grayscale(100%)"}} height={50} src={props.image} alt="pes-logo" />}
        <Typography variant="h4" style={{color: theme.palette.text.disabled}}>{props.name}</Typography>
        <Button href="#">Tento produkt již máte v košíku</Button>
      </Box>);
    
  }

}
