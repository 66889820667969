import { createMuiTheme, responsiveFontSizes  } from '@material-ui/core/styles';


let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4baf4f',
      contrastText: '#fff',
      light: '#7fe36f',
    },
    secondary: {
      main: '#464646',
      light: '#fafafa',
      contrastText: '#212121',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#4baf4f',
    },
    text: {
      primary: '#212121',
      secondary: '#53565a',
      disabled: '#9e9e9e', 
    },
    background: {
      default: '#ffffff',
    },

  },
});

theme = responsiveFontSizes(theme);

export default theme;