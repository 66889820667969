import { Step, StepLabel, Stepper } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
    currentStep: number;
    currentStepCompleted: boolean;
  }

  export interface StepItem {
    name: string;
    link:string;
  }

  const stepItems: StepItem[] = [{name:'Výběr produktu', link:"/order/products" },{name:'Fakturační údaje', link:"/order/customer" },{name:'Souhrn objednávky a platba', link:"/order/preview" }/*,{name:'Platba', link:"/order/payment" },{name:'Dokončená objednávka', link:"/order/completed" }*/];

  export const StepBar: React.FC<Props> = (props: Props) =>  {

    return (<Stepper activeStep={props.currentStep}>
        {stepItems.map((item, index) => {
          const stepProps: { completed?: boolean } = { completed: index < props.currentStep || props.currentStep == index && props.currentStepCompleted };

          return (
            <Step key={item.link} {...stepProps}>
              <StepLabel>{index > props.currentStep ? <i>{item.name}</i> : <Link to={item.link}> {item.name}</Link>}</StepLabel>
            </Step>
          );
        })}
      </Stepper>);
}