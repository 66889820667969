import React from 'react';
import { Product, Selectors } from '../features/product/types';
import { selectors as productSelectors } from '../features/product';
import { selectors as orderSelectors } from '../features/order';
import { RootState } from '../store';
import { OrderItem } from '../features/order/types';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_ORDER_ITEM } from '../features/order/actionTypes';
import { ItemUtil } from '../pages/Home';
import { Box, Button, ButtonGroup, Divider, Icon, IconButton, Paper, RadioGroup, SvgIcon, TextField, Typography, useTheme } from '@material-ui/core';
import { Wrapper } from './Wrapper';
import { Delete } from '@material-ui/icons';
import { removeOrderItem } from '../features/order/actionCreators';


interface Props {
    item: OrderItem;
    selectedAdditionalItems: OrderItem[];
    onProductSelect: (itemId:string, productId: number) => void;
    onCountSelect: (itemId:string, count: number) => void;
  }

  export const OrderItemSelector: React.FC<Props> = (props: Props) => {

    const dispatch = useDispatch();
    const theme = useTheme();
    const productGroup = useSelector((state: RootState) => productSelectors.getProductGroupById(props.item.productGroupId, state));
    const product = useSelector((state: RootState) => productSelectors.getProductById(props.item.productId, state));
  
    if(productGroup != null && product != null){ 
  
      const variantsSelector = 
      <ButtonGroup variant="contained" disableElevation  >
          {productGroup.variants.map(p => <Button key={props.item.uuid + "item"+ p.id} color={p.id === product.id ? "primary" : "inherit"} onClick={() => props.onProductSelect(props.item.uuid, p.id)} >{p.name}</Button>)}
      </ButtonGroup>;
  
     return (<Box component={Paper} style={{ borderLeft: '3px solid', borderLeftColor: theme.palette.primary.light, padding: theme.spacing(2), margin: theme.spacing(2, 0) }} key={props.item.uuid}>
        <Box display="flex" padding={0} flexDirection="row" alignContent="flex-start">
        {productGroup.image != null && <img style={{}} height={40} src={productGroup.image} alt="pes-logo" />}
        <Typography variant="h4" style={{flexGrow: 1, paddingLeft:15}} >{productGroup.name}</Typography>
        <IconButton onClick={() => dispatch(removeOrderItem(props.item))} style={{}}><Delete /></IconButton>
        </Box>
        <Divider color="primary" />
        <h3>Vyberte období</h3>
        {variantsSelector}
  
        <h3>Vyberte počet ICO</h3>
        <CountSelectorComponent count={props.item.quantity} selectors={product.countSelectors} onCountSelect={(newCount: number) =>  props.onCountSelect(props.item.uuid, newCount)} />

        <Wrapper title="K tomuto produktu lze doplnit následující produkty" hidden={productGroup.additionalVariants  && productGroup.additionalVariants.length <= 0}>
          {productGroup.additionalVariants && productGroup.additionalVariants.filter(adp => adp.requiredProducts == undefined || adp.requiredProducts.length === 0 || adp.requiredProducts.includes(product.id)).map(additionalProduct => {
            const selectedItem = props.selectedAdditionalItems.find(sp => sp.productId === additionalProduct.id);
            return selectedItem != null ? <Paper key={additionalProduct.id}> {additionalProduct.name}  <CountSelectorComponent key={productGroup.id +"-cas-"+ product.id} count={selectedItem.quantity} selectors={additionalProduct.countSelectors} onCountSelect={(newCount: number) =>  props.onCountSelect(selectedItem.uuid, newCount)} /> <IconButton onClick={() => dispatch(removeOrderItem(selectedItem))} style={{}}><Delete /></IconButton> </Paper> : <Paper key={additionalProduct.id}> {additionalProduct.name} <Button onClick={() => dispatch({ type: ADD_ORDER_ITEM, value: ItemUtil.createOrderItem(additionalProduct.id, productGroup.id, 1, props.item.uuid) })} >Přidat</Button></Paper>;
          })}

        </Wrapper>
      </Box>);
    } else {
      return <b>Produkt nenealzen</b>
    }
  }

  

export const CountSelectorComponent: React.FC<{selectors: string[], count: number, onCountSelect: (count:number)=>void }> = (props: {selectors: string[], count: number, onCountSelect: (count:number)=>void }) => {

    const countSelectors = props.selectors.includes(Selectors.FREE_RANGE) ? 
    <TextField type="number"
    InputLabelProps={{
      shrink: true,
    }} 
    defaultValue={1} 
    value={props.count} 
    onChange={(event) => props.onCountSelect(Number(event.target.value) > 0 ? Number(event.target.value) : 0 )} /> : 
    <ButtonGroup disableElevation aria-label="outlined primary button group">
        {props.selectors.includes(Selectors.FIXED_1) && <Button key={Selectors.FIXED_1} variant="contained" color={props.count === 1 ? "primary" : "inherit"}  onClick={() => props.onCountSelect(1)}>1</Button>}
        {props.selectors.includes(Selectors.FIXED_3) && <Button key={Selectors.FIXED_3} variant="contained" color={props.count === 3 ? "primary" : "inherit"} onClick={() => props.onCountSelect(3)}>3</Button>}
        {props.selectors.includes(Selectors.FIXED_10) && <Button key={Selectors.FIXED_10} variant="contained" color={props.count === 10 ? "primary" : "inherit"} onClick={() => props.onCountSelect(10)}>10</Button>}
        {props.selectors.includes(Selectors.FIXED_30) && <Button key={Selectors.FIXED_30} variant="contained" color={props.count === 30 ? "primary" : "inherit"} onClick={() => props.onCountSelect(30)}>30</Button>}
      </ButtonGroup>;

  return countSelectors;
}

  