import { UPDATE_PRODUCT_GROUPS } from './actionTypes';
import { ProductActionTypes, ProductState } from './types';

const initialState: ProductState = {
  productGroups: [],
  products: []
}

export default (state = initialState, action: ProductActionTypes) => {
  switch (action.type) {
    case UPDATE_PRODUCT_GROUPS:
      return { ...state, ...action.value }
    default:
      return state
  }
}
