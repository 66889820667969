import React, { Fragment, Component, useEffect } from 'react';

import { RootState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as systemSelectors } from '../features/system';
import { selectors as orderSelectors } from '../features/order';

import { Button, Checkbox, CircularProgress, Grid, Typography } from '@material-ui/core';
import { StepBar } from '../components/StepBar';
import globalStyles from '../style/global.style';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import qs from 'qs';
import systemReducer from '../features/system/systemReducer';

interface MatchParams {
  url: string;
}

interface Props extends RouteComponentProps<MatchParams>{

}

export const PaymentPage: React.FC<Props> = (props: Props) => {

  const classes = globalStyles();

  const {orderResult} = useSelector(orderSelectors.getOrderState);
  let gatewayUrl = orderResult?.gatewayUrl != undefined ? orderResult?.gatewayUrl : "";
  let orderCompletedUrl = orderResult?.orderId != undefined ? "/order/complete?orderId="+orderResult?.orderId : "";
  orderCompletedUrl = orderResult?.publicId != undefined ? "/order/complete?publicOrderId="+orderResult?.publicId : "";

  const orderWithPayment: boolean = orderResult?.totalPrice != undefined && orderResult?.totalPrice != 0;

  useEffect(() => {

    const redirect : string =orderWithPayment ? gatewayUrl : orderCompletedUrl;

    if (redirect != "") {
      setInterval(() => { 
        window.location.replace(redirect);
      }, 3 * 1000);
    } else {
      console.log("failed to redirect");
    }

  },[gatewayUrl]);

  return (<Grid container className={classes.root}>

      <Grid item xs={false} sm={12} md={12} >
        <StepBar currentStep={3} currentStepCompleted={true} />
        {orderWithPayment ? <Typography variant="h3">Dochází k přesměrování na platební bránu...</Typography> : <Typography variant="h3">Dochází ke zpracování objednávky... Vyčkejte prosím.</Typography>}
        <CircularProgress  />
      </Grid>

    </Grid>);
}