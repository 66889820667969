import { ADD_ORDER_ITEM, UPDATE_ORDER_ITEM, REMOVE_ORDER_ITEM, UPDATE_ORDER_CUSTOMER, UPDATE_ORDER_VOUCHER, UPDATE_ORDER } from './actionTypes'

interface AddOrderItemAction {
  type: typeof ADD_ORDER_ITEM;
  value: OrderItem;
}
interface RemoveOrderItemAction {
  type: typeof REMOVE_ORDER_ITEM;
  value: OrderItem;
}

interface UpdateOrderItemAction {
  type: typeof UPDATE_ORDER_ITEM;
  value: Partial<OrderItem>;
}

interface UpdateCustomerAction {
  type: typeof UPDATE_ORDER_CUSTOMER;
  value: Partial<Customer>;
}

interface UpdateVoucherAction {
  type: typeof UPDATE_ORDER_VOUCHER;
  value: string;
}

interface UpdateOrderStateAction {
  type: typeof UPDATE_ORDER;
  value: Partial<OrderState>;
}

export type OrderActionTypes = AddOrderItemAction | RemoveOrderItemAction | UpdateOrderItemAction | UpdateCustomerAction | UpdateVoucherAction | UpdateOrderStateAction;


export interface OrderState {
  id?: number;
  items: OrderItem[];
  customer: Customer;

  portalCode: string;
  voucher: string;
  paymentMethod: string;

  termsAndConditions: boolean;
  operatingRules: boolean;
  principlesOfPersonalDataProcessing: boolean;
  orderResult?: OrderResponse;

}

export interface OrderItem {
  uuid: string;
  productGroupId: number;
  productId: number;
  quantity: number; // pocet ico
  additionalTo?: string;
}

export interface Customer {
  id: number;
  username: string,
  email: string,
  mobile: string,
  firstName: string,
  lastName: string,
  firmName: string,
  identificationNumber: string,
  taxNumber: string,
  country: string,
  city: string,
  street: string,
  sequenceNumber: string,
  landRegistryNumber: string,
  postalCode: string,
  databoxId: string
}

export type OrderResponse = {
  voucherValid: boolean, 
  originalPrice: number, 
  totalPrice: number; 
  orderId?: number; 
  publicId?: string;
  gatewayUrl : string 
}


export enum PaymentMethods {
  NONE = "NONE",
  PAYMENT_CARD = "PAYMENT_CARD", //Platební karty
  BANK_ACCOUNT = "BANK_ACCOUNT", //Bankovní převody
  BANK_ACCOUNT_PFA = "BANK_ACCOUNT_PFA", // proforma - bankovní účet
}