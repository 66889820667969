import { Box } from '@material-ui/core';
import React from 'react';

interface WrapperProps {
    title: string;
    hidden?: boolean;
    children: any;
  }
  
export const Wrapper: React.FC<WrapperProps> = (props: WrapperProps) => {
    return (props.hidden ? <></> : 
    <Box>
      <h3>{props.title}</h3>
      {props.children}
    </Box>);
  }
  