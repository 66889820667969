import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {initialize, RootState} from './store';


import './style/index.scss';
import App from './App';

const element = document.getElementById('root');

if(element != null){
  const store = element.dataset.data ? initialize({...JSON.parse(element.dataset.data) } as RootState) : initialize({});

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    element
  )
}



