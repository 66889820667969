import React from 'react';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import globalStyles from '../style/global.style';
import { useSelector } from 'react-redux';
import { selectors as systemSelectors } from '../features/system';

const useStyle = makeStyles(theme => ({
  footer_list: { listStyle: "none" },
  footer_link: { color: theme.palette.secondary.light, fontWeight: 400 }
}));
export default function Footer() {
  const classes = { ...globalStyles(), ...useStyle() };

  const systemState = useSelector(systemSelectors.getSystemState);

  return (
    <footer className={classes.footer} >
      <Container maxWidth="lg" style={{ flexGrow: 1 }}>
        <Grid container spacing={1}>

          <Grid container item md={9} xs={12} spacing={3}>
            <div style={{ backgroundColor: "white", padding: 10, borderRadius: 5, minHeight: 60, color: "black" }}>
            <div style={{float:'left'}}>
            <a href="https://store.pespropodnikatele.cz/">
              <img alt="logo" width="200" src={systemState.baseURL + "/image/logo.png"} />
            </a>
            <br />
            <p>Copyright  © 2021</p>
            <br />
            </div>
              <h5 style={{ color: "black" }}>Partneři pro zprostředkování plateb: </h5>
              <a href="https://www.gopay.cz/" target="_blank"><img height="30" src={systemState.baseURL + "/image/gopay_bannery-barevne.png"} alt="" /></a>
              <img style={{ margin: 5 }} src={systemState.baseURL + "/image/visa.png"} height="30" alt="" />
              <img style={{ margin: 5 }} src={systemState.baseURL + "/image/master.png"} height="30" alt="" />
              <img style={{ margin: 5 }} src={systemState.baseURL + "/image/visa1.png"} height="30" alt="" />
              <img style={{ margin: 5 }} src={systemState.baseURL + "/image/visa2.png"} height="30" alt="" />
              <img style={{ margin: 5 }} src={systemState.baseURL + "/image/master2.png"} height="30" alt="" />
              <img style={{ margin: 5 }} src={systemState.baseURL + "/image/master3.png"} height="30" alt="" />
              <img style={{ margin: 5 }} src={systemState.baseURL + "/image/maestro.png"} height="30" alt="" />

            </div>

          </Grid>

          <Grid container item md={3} xs={12} spacing={3}>
            <ul className={classes.footer_list}  >
              <li><a className={classes.footer_link} href="https://pespropodnikatele.cz/#kontakt">Provozovatel systému</a></li>
              <li><a className={classes.footer_link} href="https://pespropodnikatele.cz/#kontakt">Kontaktní formulář</a></li>
              <li><a className={classes.footer_link} href="https://pespropodnikatele.cz/terms-and-conditions">Všeobecné obchodní podmínky</a></li>
              <li><a className={classes.footer_link} href="https://pespropodnikatele.cz/privacy-policy">Zásady zpracování osobních údajů</a></li>
              <li><a className={classes.footer_link} href="https://www.pespropodnikatele.cz/operating-rules">Provozní řád</a></li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}