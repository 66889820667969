import { RootState } from '../../store';

export function getOrderItems(state: RootState) {
    return state.order.items;
}

export function getOrderState(state: RootState) {
    return state.order;
}
export function getOrderBasketState(state: RootState) {
    return state.basket;
}

export function getUnavailableGroups(state: RootState) {
    return state.order.items.filter(i => i.additionalTo == null).map(i => i.productGroupId);
}
       