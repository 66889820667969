import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Paper, TextField, Typography } from '@material-ui/core';
import globalStyles from '../style/global.style';

import { selectors as basketSelector } from '../features/basket';
import { selectors as orderSelectors } from '../features/order';
import { selectors as systemSelector } from '../features/system';
import { StoreHttpClient } from '../helpers/StoreHttpClient';
import { updateVoucher } from '../features/order/actionCreators';
import { updateBasket } from '../features/basket/actionCreators';

interface Props {
  children? : ReactNode;
  }

export const Basket: React.FC<Props> = (props: Props) => {

  const systemState = useSelector(systemSelector.getSystemState);
  const orderState = useSelector(orderSelectors.getOrderState);
  const basketState = useSelector(basketSelector.getBasketState);

  const selectedItems = orderState.items;
  
  const dispatch = useDispatch();
  const classes = globalStyles();

  useEffect(() => {
      const client = new StoreHttpClient(systemState.baseURL);
      client.calcualteOrder(orderState).then((data)=>{
        dispatch(updateBasket(data));
      }).catch(e => console.error(e));
    },
    [selectedItems, orderState.voucher],
  );


  return (
    <Paper className={classes.basket} elevation={4} square={false}>
    <Typography component="h2" variant="h6">
      Souhrn objednávky
    </Typography>
    <Divider />
    <div className={classes.basket__price_container}>
    <Typography component="h5" variant="h4">
      Cena Celkem<br />
    {basketState.originalPrice != basketState.totalPrice ? <> <s>{basketState.originalPrice}</s> {basketState.totalPrice} Kč</> : <>{basketState.totalPrice} Kč</>} 
    </Typography>
    </div>
    <div className={classes.basket__price_container}>
    <Typography component="h5" variant="h6">
      Cena s DPH (21 %)<br />
       {basketState.totalVatPrice} Kč
    </Typography>
    </div>

    <form className={classes.form} noValidate>
    <TextField
        size="small"
        variant="outlined"
        margin="normal"
        fullWidth
        id="voucher"
        label="Zde vložte váš slevový voucher"
        name="voucher"
        value={orderState.voucher}
        error={(orderState.voucher !== "" && basketState.voucherValid == false) }
        helperText={orderState.voucher !== "" && basketState.voucherValid == false  ? "Zadaný voucher není platný": ""}
        onChange={(event) => dispatch(updateVoucher(event.target.value))}
      />
      <Divider />
    </form>
    {props.children} 
    </Paper>);
}

  