import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Button, Container, Link } from '@material-ui/core';
import { selectors as systemSelector } from '../features/system';
import { selectors as orderSelectors } from '../features/order';
import qs from 'qs';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.secondary.light,
            
        },
        menu: {
            backgroundColor: theme.palette.secondary.light,
        },
        title: {
            color: theme.palette.secondary.contrastText,
        },
        subTitle: {
            color: theme.palette.secondary.contrastText,
            margin: theme.spacing(2),
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        help: {
            textAlign: 'right', 
            color: theme.palette.secondary.contrastText
        },
    }),
);

export default function MenuBar() {
    const classes = useStyles();

    const system = useSelector(systemSelector.getSystemState);
    const params = qs.stringify(system.params);

    return (
        <div className={classes.root}>
            <AppBar className={classes.menu} position="static" elevation={0}>
                <Container maxWidth="lg">
                    <Toolbar>
                        <Link color="primary" href={"https://store.pespropodnikatele.cz/?"+params}>
                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <img height={25} src="https://pespropodnikatele.cz/static/images/pes-logo.svg" alt="pes-logo" />
                        </IconButton></Link>
                        <Typography className={classes.title} variant="h6" noWrap>Obchod</Typography>
                        <Typography className={classes.subTitle} variant="subtitle1" noWrap>Pes pro podnikatele</Typography>
                        <Typography className={classes.help} variant="subtitle2" noWrap>Nevíte si rady? <br/>Napíšte nám na podpora@pespropodnikatele.cz</Typography>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}