import React, { useEffect, useState, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { selectors as systemSelector } from '../features/system';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Grid, Link, Typography } from '@material-ui/core';
import globalStyles from '../style/global.style';
import { RouteComponentProps, useLocation } from 'react-router';
import qs from 'qs';
import { StoreHttpClient } from '../helpers/StoreHttpClient';


interface MatchParams {
  orderId?: string;
  publicOrderId?: string;
}

interface Props extends RouteComponentProps<MatchParams>{

}


export const CompletePage: React.FC<Props> = (props: Props) => {

  const classes = globalStyles();
  const location = useLocation();
  const [params, setParams] = useState<MatchParams>({});
  const [orderState, setOrderState] = useState("WAITING");

  const system = useSelector(systemSelector.getSystemState);
  
  const getOrderState = useCallback(async (orderId: number | string) => {
    const client = new StoreHttpClient(system.baseURL);
      client.checkOrderState(orderId).then(setOrderState).catch(e => console.warn(e));
  }, []);
  
  useEffect(() => {
    const parameters = (qs.parse(location.search, { ignoreQueryPrefix: true }) as MatchParams);
    

    if(parameters.orderId != null)
       getOrderState(Number(parameters.orderId));
    else if(parameters.publicOrderId != null)
       getOrderState(parameters.publicOrderId);

    console.log(parameters);
    setParams(parameters);
  }, []);


  

  return (
    <Grid container className={classes.root}>

      <Grid item xs={false} sm={12} md={12} >
      <div style={{textAlign: "center"}}>
        {orderState === "WAITING" && <Typography variant="h3">Načítání stavu objednávky...</Typography>}
        {orderState === "PAID" &&  <>
          <CheckCircleIcon style={{fontSize: 70}} color="primary" />
          <Typography variant="h3">Objednávka úspěšně vyřízena</Typography> 
          <Typography variant="body1">
          <br />
            Vaše přihlašovací údaje a další instrukce obdržíte do emailu. <br />
            <Link color="primary" href="https://pespropodnikatele.cz/mujPrehledPovinnosti/home">Přihlásit se můžete zde</Link>
          </Typography> 
        </>}
        {orderState === "PENDING_PAYMENT" && <Typography variant="h3">Objednávka čeká na zaplacení</Typography> }
        {orderState === "CANCELED" && <Typography variant="h3">Objednávka zrušena</Typography> }
        </div>
      </Grid>

    </Grid>);
}