import { UPDATE_PRODUCT_GROUPS } from './actionTypes';

interface UpdateProductAction {
  type: typeof UPDATE_PRODUCT_GROUPS;
  value: ProductState;
}
export type ProductActionTypes = UpdateProductAction;

export interface ProductState {
  productGroups: ProductGroup[];
  products: Product[];
}

export interface Pricelist {
  id: number;
  tiers: {quantity: number, price: number}[];
  
}

export interface ProductGroup {
  id: number,
  name: string;
  image: string;
  variants: Product[];
  additionalVariants?: Product[];

}

export interface Product {
  id: number;
  name: string;
  price: number;
  countSelectors: string[];
  requiredProducts?: number[];
}

export enum Selectors {
  FIXED_1 = "1", 
  FIXED_3 = "3",
  FIXED_10 = "10",
  FIXED_30 = "30",
  FREE_RANGE = "F"
 }