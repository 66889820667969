import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as productSelectors } from '../features/product';
import { selectors as orderSelectors } from '../features/order';
import { selectors as systemSelector } from '../features/system';
import { OrderItem } from '../features/order/types';
import { ADD_ORDER_ITEM, UPDATE_ORDER_ITEM } from '../features/order/actionTypes';

import { Wrapper } from '../components/Wrapper';
import { AdditionalProductGroup } from '../components/AdditionalProductGroup';
import { OrderItemSelector } from '../components/OrderItemSelector';

import { updateOrderItem } from '../features/order/actionCreators';
import { v4 as uuid } from 'uuid';
import { Button,  Divider, Grid, makeStyles, Paper } from '@material-ui/core';

import { Link, useHistory } from 'react-router-dom';
import { StepBar } from '../components/StepBar';
import { Basket } from '../components/Basket';
import globalStyles from '../style/global.style';


export const Home: React.FC = () => {

  const orderState = useSelector(orderSelectors.getOrderState);
  const selectedItems = orderState.items;
  const items = selectedItems.filter(i =>i.additionalTo == null);

  const unavailableGroups = useSelector(orderSelectors.getUnavailableGroups);
  const availableProductsGroups = useSelector(productSelectors.getProductGroups);

  const dispatch = useDispatch();
  const classes = globalStyles();
  let history = useHistory();


  const currentStep = 0;
  const currentStepCompleted = selectedItems.length > 0 ? true : false;

  return (
    <Grid container className={classes.root}>

      <Grid item xs={false} sm={4} md={7} >
        <StepBar currentStep={currentStep} currentStepCompleted={currentStepCompleted} />
        <Wrapper title="Vybrané produkty" hidden={!currentStepCompleted}>
          {items.map((item) =>
            <OrderItemSelector key={item.uuid} item={item}
              selectedAdditionalItems={selectedItems.filter(i => i.additionalTo === item.uuid)}
              onCountSelect={(itemId, newCount) => dispatch(updateOrderItem({ uuid: itemId, quantity: newCount }))}
              onProductSelect={(itemId, newProductId) => dispatch(updateOrderItem({ uuid: itemId, productId: newProductId, quantity: 1 }))} />
          )}
        </Wrapper>
        <Wrapper title="Další produkty">
          {availableProductsGroups.map((g, index) => {
            return (<AdditionalProductGroup key={g.id} available={!unavailableGroups.includes(g.id)} name={g.name} image={g.image}  onClick={() => dispatch({ type: ADD_ORDER_ITEM, value: ItemUtil.createOrderItem(g.variants[0].id, g.id, 1) })} />)
          })}
        </Wrapper>
      </Grid>

      <Grid item xs={12} sm={8} md={5} >
        <Basket>
          <Button disabled={!currentStepCompleted}
          type="button"
          fullWidth
          color="primary"
          variant="contained" onClick={() => {if(currentStepCompleted) history.push("/order/customer")}} >
          Pokračovat
          </Button>
        </Basket>
      </Grid>
    </Grid>
  );
}


export class ItemUtil {
  public static createOrderItem(productId: number, productGroupId: number, count: number, additionalTo?: string ): OrderItem {
    const item: OrderItem = { uuid: uuid(), quantity: count, productGroupId, productId, additionalTo };
    return item;
  }
}