import { ADD_ORDER_ITEM, REMOVE_ORDER_ITEM, UPDATE_ORDER_CUSTOMER, UPDATE_ORDER_ITEM, UPDATE_ORDER_VOUCHER, UPDATE_ORDER } from './actionTypes';
import { OrderActionTypes, OrderState, OrderItem, PaymentMethods } from './types';
import _ from "lodash";

const initialState : OrderState = {
  items: [],
  customer: {id:-1, city:"", country:"",databoxId:"", email:"",firmName:"",firstName:"", lastName:"",  identificationNumber:"", landRegistryNumber:"", mobile:"", postalCode:"", sequenceNumber:"", street:"", taxNumber:"", username:""},
  voucher: "",
  termsAndConditions: false,
  operatingRules: false,
  principlesOfPersonalDataProcessing: false,
  paymentMethod: "",
  portalCode: "default",
  
}

export default (state = initialState, action: OrderActionTypes) => {
  switch (action.type) {
    case ADD_ORDER_ITEM:
      return { ...state, items: [...state.items, action.value]  }
    case REMOVE_ORDER_ITEM: {
      let orderItems : OrderItem[] = state.items;
      const items = orderItems.filter(i => i.uuid !== action.value.uuid && i.additionalTo !== action.value.uuid);
      return { ...state, items: [...items] };
    }
    case UPDATE_ORDER_ITEM: {
      const orderItems : OrderItem[] = state.items;
      const index = orderItems.findIndex(i => i.uuid === action.value.uuid);
      orderItems[index] = {...orderItems[index], ...action.value};
      return {...state, items: [...orderItems]};
    }
    case UPDATE_ORDER: {
      return {...state, ...action.value};
    }
    case UPDATE_ORDER_CUSTOMER: 
      return {...state, customer: {...state.customer, ...action.value}};
    case UPDATE_ORDER_VOUCHER: 
      return {...state, voucher: action.value};
    default:
      return state;
  }
}
