import { combineReducers, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import orderReducer from './features/order/orderReducer';
import basketReducer from './features/basket/basketReducer';
import productReducer from './features/product/productReducer';
import systemReducer from './features/system/systemReducer';

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  order: orderReducer,
  basket: basketReducer,
  product: productReducer,
  system: systemReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export function initialize(preloadedState: Partial<RootState>) {

  return createStore(
    rootReducer,
    preloadedState,
    devToolsEnhancer({})
  );
}
