import { UPDATE_BASKET } from './actionTypes';
import { BasketActionTypes, BasketState } from './types';

const initialState: BasketState = {
  originalPrice: 0,
  totalPrice: 0, 
  totalVatPrice:0,
  voucherValid: true,
}

export default (state = initialState, action: BasketActionTypes) => {
  switch (action.type) {
    case UPDATE_BASKET:
      return { ...state, ...action.value }
    default:
      return state
  }
}
