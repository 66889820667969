import React, { Fragment, Component, useState, useCallback } from 'react';

import { RootState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as systemSelector } from '../features/system';
import { selectors as basketSelector } from '../features/basket';
import { selectors as orderSelectors } from '../features/order';
import { StepBar } from '../components/StepBar';
import { Button, Divider, FormControl, FormControlLabel, FormGroup, Grid, Link, Radio, Typography } from '@material-ui/core';
import { TextField, RadioGroup, CheckboxWithLabel } from 'formik-material-ui';
import { Basket } from '../components/Basket';
import globalStyles from '../style/global.style';
import { BasketOrderItem } from '../components/BasketOrderItem';
import { OrderState, PaymentMethods } from '../features/order/types';
import { Field, FieldProps, Form, Formik } from 'formik';
import { updateOrder } from '../features/order/actionCreators';
import { useHistory } from 'react-router-dom';
import { StoreHttpClient } from '../helpers/StoreHttpClient';
import qs from 'qs';


class FormValues {
  paymentMethod: string = "";
  termsAndConditions: boolean = false;
  operatingRules: boolean = false;
  principlesOfPersonalDataProcessing: boolean = false;
}

export const PreviewPage: React.FC = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = globalStyles();

  const orderState = useSelector(orderSelectors.getOrderState);
  const systemState = useSelector(systemSelector.getSystemState);
  const basketState = useSelector(basketSelector.getBasketState);
  const selectedItems = orderState.items;

  const handleOnSubmit = useCallback(async (values: FormValues, { setSubmitting }) => {

    dispatch(updateOrder({ operatingRules: values.operatingRules, termsAndConditions: values.termsAndConditions, principlesOfPersonalDataProcessing: values.principlesOfPersonalDataProcessing, paymentMethod: values.paymentMethod }));


    const client = new StoreHttpClient(systemState.baseURL);

    console.log(orderState, values);

    try {

      const response = await client.submitOrder({ ...orderState, paymentMethod: values.paymentMethod, operatingRules: values.operatingRules, termsAndConditions: values.termsAndConditions, principlesOfPersonalDataProcessing: values.principlesOfPersonalDataProcessing } as OrderState);

      console.log(response);

      //localStorage.setItem("order", JSON.stringify(response));

      dispatch(updateOrder({ orderResult: response }));


      history.push("/order/payment");

    } catch (e) {
      console.error(e);
      //alert("nepodařilo se odeslat objednávku");
    }

  }, [orderState]);

  const validate = (values: FormValues) => {
    const errors: any = {};

    if (values.paymentMethod == "")
      errors.paymentMethod = "Vyberte způsob platby";

    if (!values.termsAndConditions) {
      errors.termsAndConditions = 'Je třeba souhlasit s provozním řádem';
    }
    if (!values.operatingRules) {
      errors.operatingRules = 'Je třeba souhlasit s provozním řádem';
    }
    if (!values.principlesOfPersonalDataProcessing) {
      errors.principlesOfPersonalDataProcessing = 'Je třeba souhlasit se zásadami zpracování osobních údajů';
    }

    if (!values.principlesOfPersonalDataProcessing) {
      errors.principlesOfPersonalDataProcessing = 'Je třeba souhlasit se zásadami zpracování osobních údajů';
    }

    return errors;
  };


  const formValues: FormValues = {
    ...new FormValues(),
    operatingRules: orderState.operatingRules,
    termsAndConditions: orderState.termsAndConditions,
    principlesOfPersonalDataProcessing:
    orderState.principlesOfPersonalDataProcessing,
    paymentMethod: orderState.paymentMethod
  };

  return (
    <Formik
      initialValues={formValues}
      validate={validate}
      initialErrors={validate(formValues)}
      onSubmit={handleOnSubmit}>{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        validateForm,
        submitForm
      }) => (<Grid container className={classes.root}>
        <Grid item xs={false} sm={4} md={7} >
          <StepBar currentStep={2} currentStepCompleted={isValid} />
          <Typography component="h2" variant="h6">
            Souhrn vaší objednávky
            </Typography>

          <Divider />
          {selectedItems.map((item) => <BasketOrderItem key={item.uuid} item={item} />)}

          <Typography component="h2" variant="h6"> Vyberte způsob platby</Typography>
          <Divider />
          <Form>

            {basketState.totalPrice != 0 && <Field component={RadioGroup} name="paymentMethod">
              <FormControlLabel value={PaymentMethods.PAYMENT_CARD} control={<Radio value={PaymentMethods.PAYMENT_CARD} />} label='Platba kartou' />
              <FormControlLabel value={PaymentMethods.BANK_ACCOUNT} control={<Radio />} label='Platba bankovním převodem online - internetové bankovnictví' />
              <FormControlLabel value={PaymentMethods.BANK_ACCOUNT_PFA} control={<Radio />} label='Proforma faktura - získání platebních údajů' />
            </Field>}
            {basketState.totalPrice == 0 && <Field component={RadioGroup} name="paymentMethod">
              <FormControlLabel value={PaymentMethods.NONE} control={<Radio value={PaymentMethods.NONE} />} label='Bez platby' />
            </Field>}

            <Divider />

            <FormGroup>
              <FormControlLabel
                control={<Field type="checkbox" name="termsAndConditions" component={CheckboxWithLabel} Label={{ label: 'Souhlasím s obchodními podmínkami' }} />}
                label={<Typography variant="body2" ><Link target="_blank" href="https://pespropodnikatele.cz/terms-and-conditions">přečíst zde</Link></Typography>}
              />
              <FormControlLabel
                control={<Field type="checkbox" name="operatingRules" component={CheckboxWithLabel} Label={{ label: 'Beru na vědomí provozní řád' }} />}
                label={<Typography variant="body2" ><Link target="_blank" href="https://pespropodnikatele.cz/operating-rules">přečíst zde</Link></Typography>}
              />
              <FormControlLabel
                control={<Field type="checkbox" name="principlesOfPersonalDataProcessing" component={CheckboxWithLabel} Label={{ label: 'Uděluji souhlas se spracováním osobních údajů' }} />}
                label={<Typography variant="body2" ><Link target="_blank" href="https://pespropodnikatele.cz/privacy-policy">přečíst zde</Link></Typography>}
              />
            </FormGroup>

            <Button type="submit" disabled={!isValid || isSubmitting} color="primary" >Potvrdit objednávku a zaplatit</Button>
          </Form>

        </Grid>
        <Grid item xs={12} sm={8} md={5} >
          <Basket>
            <Button type="submit" fullWidth color="primary" variant="contained" disabled={!isValid || isSubmitting} onClick={() => { submitForm() }} >Potvrdit objednávku a zaplatit</Button>
            <Button type="button" fullWidth color="secondary" onClick={() => { history.push("/order/customer") }}>Zpět</Button>
          </Basket>
        </Grid>
      </Grid>)}</Formik>);
}