import React, { Fragment, Component, useEffect, useState, useCallback } from 'react';

import { RootState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as productSelectors } from '../features/product';
import { selectors as orderSelectors } from '../features/order';
import { selectors as systemSelector } from '../features/system';

import { StoreHttpClient } from '../helpers/StoreHttpClient';

import { Button, CircularProgress, FormControlLabel, FormGroup, FormLabel, Grid, makeStyles, Paper, Radio, Typography } from '@material-ui/core';
import { StepBar } from '../components/StepBar';
import globalStyles from '../style/global.style';
import { Basket } from '../components/Basket';
import { updateCustomer, updateOrder } from '../features/order/actionCreators';
import { Customer, PaymentMethods } from '../features/order/types';
import { Formik, Field, Form, useFormikContext, useField } from 'formik';
import { TextField, RadioGroup, CheckboxWithLabel } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(theme => ({ 
  root: { minHeight: '80vh', margin: theme.spacing(1)}, 
  field:{ margin: theme.spacing(1)+"px!important" }
}));

export class FormValues implements Customer {
  id: number = -1;
  username: string = "";
  email: string = "";
  mobile: string = "";
  firstName: string = "";
  lastName: string = "";
  firmName: string = "";
  identificationNumber: string = "";
  taxNumber: string = "";
  country: string = "";
  city: string = "";
  street: string = "";
  sequenceNumber: string = "";
  landRegistryNumber: string = "";
  postalCode: string = "";
  databoxId: string = "";



}

const AresInput: React.FC<{ name: string, label: string, type: string }> = (props) => {
  const system = useSelector(systemSelector.getSystemState);
  const client = new StoreHttpClient(system.baseURL);

  const [isFetching, setFetching] = useState(false);
  const classes = useStyles();

  const {
    values: { identificationNumber },
    setFieldValue,
    validateForm,
    setSubmitting
  } = useFormikContext<FormValues>();
  const [field, meta] = useField(props);

  React.useEffect(() => {

    if (field.value != null && field.value.length == 8) {
      setFieldValue("identificationNumber", field.value, true);

      setFetching(true);
      setSubmitting(true);
      client.getContactInfoFromAres(field.value).then(data => {

        setFetching(false);
        setSubmitting(false);

        if (data != null && data.identificationNumber != null) {
          if (data.identificationNumber != "null")
            setFieldValue("identificationNumber", data.identificationNumber, false);
          if (data.name != "null")
            setFieldValue("firmName", data.name, false);
          if (data.landRegistryNumber != "null")
            setFieldValue("landRegistryNumber", data.landRegistryNumber, false);
          if (data.sequenceNumber != "null")
            setFieldValue("sequenceNumber", data.sequenceNumber, false);
          if (data.street != "null")
            setFieldValue("street", data.street, false);
          if (data.city != "null")
            setFieldValue("city", data.city, false);
          if (data.postalCode != "null")
            setFieldValue("postalCode", data.postalCode, false);
          if (data.country != "null")
            setFieldValue("country", data.country, false);
        }

        validateForm();

      }).catch(e => {
        console.error(e);
        setFetching(false);
        setSubmitting(false);
      });
    } else {
      setFieldValue("identificationNumber", field.value, true);
    }
  }, [field.value, setFieldValue, props.name]);

  return (
    <>
      <Field className={classes.field} {...props} {...field} component={TextField} /> {isFetching && <CircularProgress />}
    </>
  );
};

export const CustomerPage: React.FC = () => {

  const orderState = useSelector(orderSelectors.getOrderState);
  const dispatch = useDispatch();
  let history = useHistory();

  const [customerIsValid, setCustomerIsValid] = useState(false);


  const classes = useStyles();

  const validate = (values: FormValues) => {
    const errors: any = {};
    if (!values.firstName || values.firstName.length < 1) {
      errors.firstName = 'Povinné pole';
    }

    if (!values.lastName || values.lastName.length < 1) {
      errors.lastName = 'Povinné pole';
    }

    if (!values.identificationNumber || values.identificationNumber.length < 1) {
      errors.identificationNumber = 'Povinné pole';
    } else if (values.identificationNumber && values.identificationNumber.length !== 8 || !/^[0-9]+$/i.test(values.identificationNumber)) {
      errors.identificationNumber = 'Neplatný formát ičo';
    }

    if (!values.firmName || values.firmName.length < 1) {
      errors.firmName = 'Povinné pole';
    }

    if (!values.country || values.country !== "Česká republika") {
      errors.country = 'Povinná hodnota je \"Česká republika\"';
    }
    if (!values.city || values.city.length < 1) {
      errors.city = 'Povinné pole';
    }
    if (!values.postalCode || values.postalCode.length < 1) {
      errors.postalCode = 'Povinné pole';
    } else if (values.postalCode && values.postalCode.length !== 5 || !/^[0-9]+$/i.test(values.postalCode)) {
      errors.postalCode = 'Neplatný formát PSČ';
    }

    if (!values.street || values.street.length < 1) {
      errors.street = 'Povinné pole';
    }

    if (!values.landRegistryNumber || values.landRegistryNumber.length < 1) {
      errors.landRegistryNumber = 'Povinné pole';
    }

    const mobile = values.mobile.startsWith("+420") ? values.mobile.substring(4) : values.mobile;
    if (!values.mobile || mobile.length < 1) {
      errors.mobile = 'Povinné pole';
    } else if (!/^[0-9]+$/i.test(mobile) || mobile.length < 9) {
      errors.mobile = 'Neplatný formát telefoního čísla';
    }

    if (!values.email) {
      errors.email = 'Povinné pole';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Neplatný email';
    }

    let isValid = customerIsValid;
    if (Object.keys(errors).length > 0)
      isValid = true;

    if (isValid !== customerIsValid)
      setCustomerIsValid(isValid);

    return errors;
  };

  const onSubmit = useCallback(async (values: FormValues, actions) => {
    dispatch(updateCustomer(values));

    actions.setSubmitting(false);
    history.push("/order/preview");
  }, []);

  const formValues: FormValues = { ...new FormValues(), ...orderState.customer };

  return (
    <Formik
      initialValues={formValues}
      validate={validate}
      onSubmit={onSubmit}>{({
        isValid,
        validateForm,
        submitForm
      }) => (
        <Grid container className={classes.root}>

          <Grid item xs={false} sm={4} md={7} >

            <Form>
              <StepBar currentStep={1} currentStepCompleted={customerIsValid} />
              <Typography component="h2" variant="h6">Kontaktní a fakturační údaje</Typography>
              

              <Grid item>
              <Typography className={classes.field} variant="overline">Kontaktní osoba</Typography><br />
                <Field
                  className={classes.field}
                  name="firstName"
                  label="Jméno"
                  type="text"
                  component={TextField}
                />
                <Field
                  className={classes.field}
                  name="lastName"
                  label="Příjmení"
                  type="text"
                  component={TextField}
                />

              </Grid>

              <Grid item>
                <Field
                  className={classes.field}
                  name="email"
                  label="Email"
                  type="text"
                  component={TextField}
                />
                <Field
                  className={classes.field}
                  name="mobile"
                  label="Telefonní číslo"
                  type="text"
                  component={TextField}
                /><br />


              <Grid item>
                <AresInput
                  name="identificationNumber"
                  label="IČO"
                  type="text"
                /><br />
                <Typography className={classes.field} variant="caption">(Po vyplnění IČO dojde k vypnění údajů z ARES)</Typography>
              </Grid>

                <Field
                  className={classes.field} style={{width:"350px"}}
                  name="firmName"
                  label="Název firmy / OSVČ"
                  type="text"
                  component={TextField}
                />
              </Grid>

              <Grid item>
                <Typography className={classes.field} variant="overline">Adresa</Typography><br />
                <Field
                  className={classes.field}
                  name="street"
                  label="Ulice"
                  type="text"
                  component={TextField}
                />
                <Field
                  className={classes.field}
                  name="landRegistryNumber"
                  label="Číslo popisné"
                  type="text"
                  component={TextField}
                />
                <Field
                  className={classes.field}
                  name="sequenceNumber"
                  label="Číslo orientační"
                  type="text"
                  component={TextField}
                />
                <br />
                <Field
                  className={classes.field}
                  name="postalCode"
                  label="PSČ"
                  type="text"
                  component={TextField}
                />
                <Field
                  className={classes.field}
                  name="country"
                  label="Stát"
                  type="text"
                  component={TextField}
                />
                <Field
                  className={classes.field}
                  name="city"
                  label="Město"
                  type="text"
                  component={TextField}
                />

              </Grid>
              <Grid item>
                <Typography className={classes.field} variant="overline">Dodatečné údaje</Typography><br />
                <Field
                  className={classes.field}
                  name="databoxId"
                  label="ID datové schránky"
                  type="text"
                  component={TextField}
                />
                <Field
                  className={classes.field}
                  name="taxNumber"
                  label="DIČ"
                  type="text"
                  component={TextField}
                />

              </Grid>
              <Grid item>
                <Button type="submit" disabled={!isValid} color="primary">
                  Pokračovat
                </Button>

              </Grid>
            </Form>

          </Grid>

          <Grid item xs={12} sm={8} md={5} >
            <Basket>
              {<Button type="button" fullWidth color="primary" disabled={!isValid} variant="contained" onClick={() => { submitForm() }}>Pokračovat</Button>}
              <Button type="button" fullWidth color="secondary" onClick={() => { history.push("/order/products") }}>Zpět</Button>
            </Basket>
          </Grid>
        </Grid >)}</Formik>);
}