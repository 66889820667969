import { uniqueId } from "lodash";
import { OrderResponse, OrderState } from "../features/order/types";
import { ProductGroup } from "../features/product/types";
import { HttpClient } from "./HttpClient";

export class StoreHttpClient extends HttpClient {

  protected static isSubmitting: boolean = false;
  public constructor(baseUrl: string) {
    super(baseUrl);

  }

  public async getProductGroups(partner: string = "default") {
    return this.instance.get<ProductGroup[]>('/api/store/product_group?portalCode={PARTNER}'.replace("{PARTNER}", partner));
  }

  public async calcualteOrder(orderState: OrderState) {
    return this.instance.post<{ voucherValid: boolean, originalPrice: number, totalPrice: number, totalVatPrice: number }>('/api/store/order/calculate', orderState);
  }

  public async submitOrder(orderState: OrderState) {
    if (StoreHttpClient.isSubmitting) {
      throw new Error("order is submitting");
    }

    try {
      StoreHttpClient.isSubmitting = true;
      const rp = await this.instance.post<OrderResponse>('/api/store/order/submit', orderState);
      return rp;
    } catch (e) {
      throw e;
    }
    finally {
      StoreHttpClient.isSubmitting = false;
    }


  }

  public async checkOrderState(orderId: number | string) {
    if (StoreHttpClient.isSubmitting) {
      throw new Error("order is submitting");
    }

    try {
      StoreHttpClient.isSubmitting = true;
      const rp = await this.instance.get<string>((Number.isInteger(orderId) ? '/api/store/order/state?orderId=' : '/api/store/order/state?publicOrderId=') + orderId);
      return rp;
    } catch (e) {
      throw e;
    } finally {
      StoreHttpClient.isSubmitting = false;
    }

  }


  public async getContactInfoFromAres(identificationNumber: string) {
    return this.instance.get<ContactInfoAres>('/api/ares/{identification_number}'.replace("{identification_number}", identificationNumber));
  }
}

export interface ContactInfoAres {
  identificationNumber: string;
  name: string;
  city: string;
  street: string;
  sequenceNumber: string;
  landRegistryNumber: string;
  postalCode: string;
  country: string;
}