import React from 'react';
import { Product } from '../features/product/types';
import { selectors as productSelectors } from '../features/product';
import { RootState } from '../store';
import { OrderItem } from '../features/order/types';
import { useSelector } from 'react-redux';
import { Divider, makeStyles, Typography } from '@material-ui/core';

interface Props {
  item: OrderItem;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    borderBottom: '1px dotted gray'
  },
}));

export const BasketOrderItem: React.FC<Props> = (props: Props) => {

  const classes = useStyles();
    const productGroup = useSelector((state: RootState) => productSelectors.getProductGroupById(props.item.productGroupId, state));
  const product = useSelector((state: RootState) => productSelectors.getProductById(props.item.productId, state));

  return (<div className={classes.root}>
    <Typography variant="h6"> {productGroup?.name} - {product?.name} x {props.item.quantity}</Typography>
  </div>);
}

