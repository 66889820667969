import { makeStyles } from "@material-ui/core";

const globalStyles = makeStyles((theme) => ({
  root: {
    minHeight: '80vh',
    margin: theme.spacing(2, 2, 4, 2),
  },
  basket: {
    margin: theme.spacing(2, 2),
    padding: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
    minHeight: '60vh',
    background: theme.palette.secondary.light
  },
  basket__price_container: {
    //margin: theme.spacing(2, 1),
    padding: theme.spacing(3, 1, 0, 1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  field: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  footer: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'flext-end',
    background: "#373a37", 
    color: "#dadada",
    minHeight: '20vh'
  },
}));

  export default globalStyles;