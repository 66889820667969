import { UPDATE_PRODUCT_GROUPS } from "./actionTypes";
import { ProductGroup, ProductActionTypes, ProductState, Product } from "./types";


export function updateProductGroups(groups: ProductGroup[]) : ProductActionTypes {

    const products: Product[] = [];

    groups.forEach(group => { 
      group.variants.forEach(pr => products.push(pr)); 
      group.additionalVariants?.forEach(pr => products.push(pr)); 
    });

    return {
      type: UPDATE_PRODUCT_GROUPS,
      value: { productGroups: groups, products: products } as ProductState,
    }
}
