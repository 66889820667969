import { UPDATE_SYSTEM } from './actionTypes';
import { SystemActionTypes, SystemState } from './types';

const initialState: SystemState = {
  params: {},
  baseURL: ""
}

export default (state = initialState, action: SystemActionTypes) => {
  switch (action.type) {
    case UPDATE_SYSTEM:
      return { ...state, ...action.value }
    default:
      return state
  }
}
