import React from 'react'
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import qs from 'qs';

import { CustomerPage } from './pages/CustomerPage'
import { Home, ItemUtil } from './pages/Home'
import { connect } from 'react-redux';
import { addOrderItem, updateOrder, updateOrderItem, updateVoucher } from './features/order/actionCreators';
import { RootState } from './store';
import { SearchParams } from './features/system/types';
import { updateProductGroups } from './features/product/actionCreators';
import { updateSystem } from './features/system/actionCreators';
import { StoreHttpClient } from './helpers/StoreHttpClient';
import { selectors } from './features/product';
import { Product, ProductGroup } from './features/product/types';
import { Box, CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from './style/global.theme';
import Container from '@material-ui/core/Container/Container';
import { PreviewPage } from './pages/PreviewPage';
import MenuBar from './components/MenuBar';
import Footer from './components/Footer';
import { PaymentPage } from './pages/PaymentPage';
import { CompletePage } from './pages/CompletePage';

interface Props {
  baseURL: string,
  groups: ProductGroup[],
  dispatch: (action: any) => void;
}
interface State {
  loading: boolean
}

class App extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

  }
  componentDidMount() {
    const values = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as SearchParams;
    this.props.dispatch(updateSystem({ params: { ...values } }));
    this.props.dispatch(updateOrder({portalCode: values.partner ? values.partner : "default"}));

    if(values.voucher != null){
      this.props.dispatch(updateVoucher(values.voucher));
    }

    
    
    const client = new StoreHttpClient(this.props.baseURL);
    this.setState({ loading: true });
    client.getProductGroups(values.partner).then((groups) => {
      this.props.dispatch(updateProductGroups(groups));

      if (values.product != null) {
        
        let product : Product | undefined;
        const group = groups.find(g => {
          product = g.variants.find(p => p.id === Number(values.product));

          if(product != null)
             return g;
        });

        if (group == null || product == null)
          return;

        this.props.dispatch(addOrderItem(ItemUtil.createOrderItem(product.id, group.id, 1)));
      }

    })
      .catch((e) => console.error(e))
      .finally(() => this.setState({ loading: false }));

  }

  render() {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <MenuBar></MenuBar>
        <Container maxWidth="lg">  
              <Switch>
                <Route path={["/", "/order", "/order/products"]} component={Home} exact />
                <Route path="/order/customer" component={CustomerPage} />
                <Route path="/order/preview" component={PreviewPage} />
                <Route path="/order/payment" component={PaymentPage} />
                <Route path="/order/complete" component={CompletePage} />
              </Switch>
          </Container>
          <Footer />
        </ThemeProvider>
      </BrowserRouter>)
  }



}

const mapStateToProps = (state: RootState) => ({ baseURL: state.system.baseURL, groups: selectors.getProductGroups(state) });
export default connect(mapStateToProps)(App);
