import { ADD_ORDER_ITEM, UPDATE_ORDER_ITEM, REMOVE_ORDER_ITEM, UPDATE_ORDER_VOUCHER, UPDATE_ORDER_CUSTOMER, UPDATE_ORDER } from "./actionTypes";
import { OrderItem, OrderActionTypes, Customer, OrderState } from "./types";

export function addOrderItem(item: OrderItem) : OrderActionTypes {
    return {
      type: ADD_ORDER_ITEM,
      value: item,
    }
}

export function removeOrderItem(item: OrderItem) : OrderActionTypes {
    return {
      type: REMOVE_ORDER_ITEM,
      value: item,
    }
}

export function updateOrderItem(item: Partial<OrderItem>) : OrderActionTypes {
  return {
    type: UPDATE_ORDER_ITEM,
    value: item,
  }
}

export function updateVoucher(voucher: string) : OrderActionTypes {
  return {
    type: UPDATE_ORDER_VOUCHER,
    value: voucher,
  }
}

export function updateCustomer(customer: Partial<Customer>) : OrderActionTypes {
  return {
    type: UPDATE_ORDER_CUSTOMER,
    value: customer,
  }
}


export function updateOrder(newState: Partial<OrderState>) : OrderActionTypes {
  return {
    type: UPDATE_ORDER,
    value: newState,
  }
}